/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/

//External
import Question from "components/Survey/UXQuestions/Question";
import React, { useEffect, useState, useRef } from "react";

//Internal
import styles from "./Survey.module.scss";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import { SurveyButton } from "./SurveyButton/SurveyButton";
import SurveyCompleted from "./SurveyCompleted";
// import Section from "components/QuestionConstruction/Section";
import useOnScreen from "components/UseOnScreen/useOnScreen";
import { useGetQsAndSections } from "api/resources/projects/questions";
import { useFetchProjectAndImage } from "api/resources/projects/projects";
import { ErrorPage } from "pages";
import { Loading } from "components/Loading/Loading";
import { SurveyLogo } from "./SurveyLogo";
import Section from "components/Survey/UXQuestions/Section";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";

/**
 *  Generic Survey Component. Shows the questions that you pass into it with the disired settings that have also been passed in as a prop
 * @param {object} settings object to show settings (logoSource: string, navButtons: array of boolean color values, showProgBar: boolean, showProgQs: boolean, aligned: string ('left', 'right', 'center'))
 * @param {object} questions state update function, used to update settings object
 * @returns
 */
function Survey({
  saveAnswer,
  retractAnswer,
  retractAnswers,
  projectId,
  participationId,
  onFinish,
  preview,
  alreadyTaken,
  noLongerActive,
  preloadAnswers,
  stats,
  startEntryTimer,
}) {
  const [currPageNum, setCurrPageNum] = useState(1);
  const [allQuestions, setAllQuestions] = useState();
  const [justQuestions, setJustQuestions] = useState();
  const [page, setPage] = useState();
  const [numQs, setNumQs] = useState();
  const [numAnswered, setNumAnswered] = useState(0);
  const [completed, setCompleted] = useState(alreadyTaken ? true : false);
  const [numPages, setNumPages] = useState();
  const [answerMap, setAnswerMap] = useState({});
  const [settings, setSettings] = useState();
  const [dependencies, setDependencies] = useState();
  const [otherMap, setOtherMap] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const [surveyOpen, setSurveyOpen] = useState(false);
  const fetchQsAndSections = useGetQsAndSections();
  const fetchProject = useFetchProjectAndImage();
  const [finishedMsg, setFinishedMsg] = useState("");
  const [passing, setPassing] = useState();

  function setQsAndSections(questions, sections) {
    setNumQs(questions.length);

    for (let i = 0; i < sections.length; i++) {
      if (typeof sections[i].sectionSettings === "string") {
        sections[i].sectionSettings = JSON.parse(sections[i].sectionSettings);
      }
      if (
        sections[i]?.conditionGroups &&
        typeof sections[i].conditionGroups === "string"
      ) {
        sections[i].conditionGroups = JSON.parse(sections[i].conditionGroups);
      }
    }

    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i]?.conditionGroups &&
        typeof questions[i].conditionGroups === "string"
      ) {
        questions[i].conditionGroups = JSON.parse(questions[i].conditionGroups);
      }
      if (questions[i].triggers && typeof questions[i].triggers === "string") {
        questions[i].triggers = JSON.parse(questions[i].triggers);
      }
      if (
        questions[i].matrixQuestion &&
        typeof questions[i].matrixQuestion.settings === "string"
      ) {
        questions[i].matrixQuestion.settings = JSON.parse(
          questions[i].matrixQuestion.settings
        );
      }
    }
    setJustQuestions(questions);
    let all = getSortedArray([...questions, ...sections], (a, b) => {
      if (a.pageNumber === b.pageNumber) {
        return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
      } else {
        return a.pageNumber > b.pageNumber ? 1 : -1;
      }
    });
    setAllQuestions(all);

    let thisPage = [];
    for (let q of all) {
      if (q.pageNumber === currPageNum) {
        thisPage.push(q);
      }
    }

    setPage(thisPage);

    setUpPassing(all);
    setUpDependencies(all);

    if (preloadAnswers && preloadAnswers.length) {
      preload();
    }

    if (startEntryTimer) {
      startEntryTimer();
    }
  }

  function setUpPassing(allQs) {
    let state = {};
    for (let q of allQs) {
      state[q.id] = clearsConditions(q);
    }
    setPassing(state);
    checkPages(state, allQs);
  }

  function setUpDependencies(all) {
    let dependentRecord = [];
    for (let q of all) {
      if (
        q.type !== "Description" &&
        q?.conditionGroups &&
        q.conditionGroups.length
      ) {
        let dependencies = [];
        for (let group of q.conditionGroups) {
          if (group?.operator && group?.conditions && group.conditions.length) {
            let gDep = groupDependency(group);
            if (gDep) {
              dependencies.push(gDep);
            }
          }
        }

        if (dependencies.length) {
          let record = {
            affected: q.id,
            dependencies: dependencies,
            affectedName: q.questionText,
          };
          dependentRecord.push(record);
        }
      }
    }

    if (dependentRecord.length) {
      // Adding on inherited dependencies
      // Should catch all because of question order
      for (let record of dependentRecord) {
        for (let group of [...record.dependencies]) {
          for (let condition of group.conditions) {
            let parents = dependentRecord.find(
              (r) => r.affected === condition.questionId
            );
            if (parents) {
              record.dependencies = [
                ...parents.dependencies,
                ...record.dependencies,
              ];
            }
          }
        }
      }

      setDependencies(dependentRecord);
    }
  }

  function groupDependency(group) {
    let invalidatedWhen = {
      conditions: [],
      operator: "",
    };

    let op = group.operator;
    if (op === "When any condition matches") {
      invalidatedWhen.operator = "When all conditions match"; // When all flipped are true
    } else if (op === "When any condition does not match") {
      invalidatedWhen.operator = "When no condition matches"; // When no flipped condition is true
    } else if (op === "When all conditions match") {
      invalidatedWhen.operator = "When any condition matches"; // When any flipped condition is true
    } else if (op === "When no condition matches") {
      invalidatedWhen.operator = "When any condition does not match"; // When any flipped condition is not true
    }
    for (let condition of group.conditions) {
      let dependency = conditionDependency(condition);
      if (dependency) {
        invalidatedWhen.conditions.push(dependency);
      }
    }

    if (invalidatedWhen.conditions.length) {
      return invalidatedWhen;
    }
    return null;
  }

  function conditionDependency(condition) {
    if (!condition?.operator || !condition?.questionId) {
      return null;
    }

    let falseOn = {
      questionId: condition.questionId,
      answer: null,
      operator: "",
      option: condition.option,
    };

    // What will make this condition return false?

    let op = condition.operator;
    if (op === "have answered") {
      falseOn.operator = "have not answered";
    } else if (op === "have not answered") {
      falseOn.operator = "have answered";
    } else if (op === "answer") {
      falseOn.operator = "do not answer";
      falseOn.answer = condition.answer;
    } else if (op === "do not answer") {
      falseOn.operator = "answer";
      falseOn.answer = condition.answer;
    } else if (op === "between") {
      falseOn.operator = "outside";
      falseOn.answer = condition.answer;
    } else if (op === "below") {
      falseOn.operator = "above";
      falseOn.answer = condition.answer - 1;
    } else if (op === "above") {
      falseOn.operator = "below";
      falseOn.answer = condition.answer + 1;
    } else if (op === "contains") {
      falseOn.operator = "does not contain";
      falseOn.answer = condition.answer;
    } else if (op === "does not contain") {
      falseOn.operator = "contains";
      falseOn.answer = condition.answer;
    } else if (op === "is longer than") {
      falseOn.operator = "is shorter than";
      falseOn.answer = condition.answer + 1;
    } else if (op === "is shorter than") {
      falseOn.operator = "is longer than";
      falseOn.answer = condition.answer - 1;
    }

    return falseOn;
  }

  function fetchDSettings() {
    fetchProject.mutate(
      {
        id: projectId,
      },
      {
        onSuccess: (data) => {
          let dSettings = JSON.parse(
            data.getProjectAndImageUrl?.project?.designSettings
          );
          setSettings(dSettings);
          setFinishedMsg(data.getProjectAndImageUrl?.project?.finishedMessage);

          if (data.getProjectAndImageUrl.imageURL) {
            setImageUrl(data.getProjectAndImageUrl.imageURL);
          } else {
            setImageUrl(
              "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
            );
          }

          if (
            data.getProjectAndImageUrl?.project.status === "Open" ||
            preview
          ) {
            if (
              !noLongerActive &&
              (!alreadyTaken || (alreadyTaken && dSettings.returnable))
            ) {
              fetchQs();
            }

            setSurveyOpen(true);
          } else {
            setSurveyOpen(false);
          }
        },
        onError: (data) => {},
      }
    );
  }

  function fetchQs() {
    fetchQsAndSections.mutate(
      {
        projectId: projectId,
      },
      {
        onSuccess: (data) => {
          let qs = data.QsAndSectionsInSurvey.questions;
          let sections = data.QsAndSectionsInSurvey.sections;
          setQsAndSections(qs, sections);
        },
      }
    );
  }

  function preload() {
    let copy = { ...answerMap };
    let num = 0;
    for (let answer of preloadAnswers) {
      let mapAnswer;
      if (answer?.matrixAnswer) {
        mapAnswer = JSON.parse(answer.matrixAnswer);
      } else if (answer?.textAnswer) {
        mapAnswer = answer.textAnswer;
      } else if (answer?.scaleAnswer) {
        mapAnswer = answer.scaleAnswer;
      } else if (answer.choiceAnswer) {
        mapAnswer = answer.choiceAnswer;
      }
      copy[answer.questionId] = mapAnswer;

      num++;
    }
    setAnswerMap(copy);
    setNumAnswered(num);
  }

  useEffect(() => {
    fetchDSettings(); // Fetch Design Settings no matter what
  }, []);

  function incrementAnswerCount() {
    setNumAnswered(numAnswered + 1);
  }

  function decrementAnswerCount() {
    setNumAnswered(numAnswered - 1);
  }

  function changeToPage(number) {
    let thisPage = [];

    while (thisPage.length == 0) {
      for (let q of allQuestions) {
        if (q.pageNumber === number) {
          thisPage.push(q);
        }
      }

      let able = [];
      for (let q of thisPage) {
        if (passing[q.id]) {
          able.push(q);
        }
      }
      if (able.length == 0) {
        thisPage = [];
        number > currPageNum ? number++ : number--;
      }
    }

    setCurrPageNum(number);
    setPage(thisPage);

    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  function nextPage() {
    if (currPageNum + 1 <= numPages) {
      changeToPage(currPageNum + 1);
    }
  }
  function previousPage() {
    if (currPageNum - 1 > 0) {
      changeToPage(currPageNum - 1);
    }
  }

  function finishSurvey() {
    if (onFinish) {
      onFinish();
    }
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setCompleted(true);
  }

  function onAnswered(questionID, answer) {
    // console.log("answered", answer);

    if (saveAnswer) {
      let answerObj = {
        participationId: participationId ? participationId : "",
        questionId: questionID,
      };
      let question = allQuestions.find((q) => q.id === questionID);
      if (question.matrixQuestion) {
        answerObj.matrixAnswer = JSON.stringify(answer);
      } else if (question.scaleQuestion) {
        answerObj.scaleAnswer = answer;
      } else if (question.textQuestion) {
        answerObj.textAnswer = answer;
      } else if (question.choiceQuestion) {
        answerObj.choiceAnswer = answer;
        answerObj.choiceAnswerIndex = findOptionIndeces(questionID, answer);
      }

      let increment = !Object.keys(answerMap).length;
      saveAnswer(answerObj, increment);
    }

    if (!(questionID in answerMap)) {
      incrementAnswerCount();
    }

    let copy = { ...answerMap };
    copy[questionID] = answer;
    setAnswerMap(copy);
  }

  function findOptionIndeces(questionID, answer) {
    let question = allQuestions.find((q) => q.id === questionID);
    let answerIndexArray = [];
    forEach(question.choiceQuestion.choices, (choice, index) => {
      if (answer.includes(choice)) {
        answerIndexArray.push(index);
      }
    });

    if (question.choiceQuestion.hasOtherOption) {
      for (let i = 0; i < answer.length; i++) {
        let found = question.choiceQuestion.choices.find(
          (opt) => opt === answer[i]
        );
        if (!found) {
          // This is the 'other' option;
          answerIndexArray.push(question.choiceQuestion.choices.length);
          let newOtherMap = { ...otherMap };
          newOtherMap[questionID] = answer[i];
          setOtherMap(newOtherMap);
        }
      }
    }
    return answerIndexArray;
  }

  function onRetract(questionID) {
    // console.log("retracted");
    let copy = { ...answerMap };
    delete copy[questionID];
    setAnswerMap(copy);

    if (retractAnswer) {
      let decrement = !Object.keys(copy).length;
      retractAnswer(questionID, decrement);
    }

    decrementAnswerCount();
  }

  // const progressbar = useRef(null);
  const isVisible = true; // useOnScreen(progressbar);

  function clearsConditions(question) {
    if (question?.conditionGroups) {
      for (let group of question.conditionGroups) {
        if (!checkGroup(group)) {
          return false;
        }
      }
    }
    return true;
  }

  function checkGroup(group) {
    if (!group?.operator) {
      return true;
    }
    let op = group.operator;
    if (op === "When any condition matches") {
      for (let condition of group.conditions) {
        if (checkCondition(condition)) {
          return true;
        }
      }
      return false;
    } else if (op === "When any condition does not match") {
      for (let condition of group.conditions) {
        if (!checkCondition(condition)) {
          return true;
        }
      }
      return false;
    } else if (op === "When all conditions match") {
      for (let condition of group.conditions) {
        if (!checkCondition(condition)) {
          return false;
        }
      }
      return true;
    } else if (op === "When no condition matches") {
      for (let condition of group.conditions) {
        if (checkCondition(condition)) {
          return false;
        }
      }
      return true;
    }
  }

  function checkCondition(condition) {
    if (!condition.operator || !condition.questionId) {
      return true;
    }
    let op = condition.operator;
    if (op === "have answered") {
      return condition.questionId in answerMap;
    }
    if (op === "have not answered") {
      return !(condition.questionId in answerMap);
    }

    if (!(condition.questionId in answerMap)) {
      return false;
    }
    let answer = answerMap[condition.questionId];

    let qInQuestion = allQuestions.find((q) => q.id === condition.questionId);
    if (qInQuestion) {
      if (qInQuestion.matrixQuestion) {
        if (!(condition.option in answer)) {
          return false;
        }
        answer = answer[condition.option];
      }
      if (qInQuestion.choiceQuestion) {
        if (op === "answer") {
          // An 'or' on the condition.answer
          for (let option of condition.answer) {
            if (answer.includes(option)) {
              return true;
            }
          }

          // Check for other option
          if (
            qInQuestion.choiceQuestion.hasOtherOption &&
            condition.answer.includes(
              qInQuestion.choiceQuestion.otherOptionLabel
            )
          ) {
            let answerIndeces = findOptionIndeces(qInQuestion.id, answer);
            let otherInd = qInQuestion.choiceQuestion.choices.length;
            if (answerIndeces.includes(otherInd)) {
              return true;
            }
          }

          return false;
        } else if (op === "do not answer") {
          for (let option of condition.answer) {
            if (answer.includes(option)) {
              return false;
            }
          }

          // Check for other option
          if (
            qInQuestion.choiceQuestion.hasOtherOption &&
            condition.answer.includes(
              qInQuestion.choiceQuestion.otherOptionLabel
            )
          ) {
            let answerIndeces = findOptionIndeces(qInQuestion.id, answer);
            let otherInd = qInQuestion.choiceQuestion.choices.length;
            if (answerIndeces.includes(otherInd)) {
              return false;
            }
          }
        }
      }
      if (qInQuestion.scaleQuestion) {
        if (op === "answer") {
          for (let option of condition.answer) {
            // An 'or' on the condition.answer
            if (option == answer) {
              // Not strongly equal
              return true;
            }
          }
          return false;
        } else if (op === "do not answer") {
          for (let option of condition.answer) {
            if (option == answer) {
              // Not strongly equal
              return false;
            }
          }
        } else if (op === "between") {
          let order = getSortedArray(condition.answer, (a, b) => a - b);
          if (answer < order[0] || answer > order[1]) {
            return false;
          }
        } else if (op === "outside") {
          let order = getSortedArray(condition.answer, (a, b) => a - b);
          if (answer >= order[0] && answer <= order[1]) {
            return false;
          }
        } else if (op === "below") {
          return answer < condition.answer;
        } else if (op === "above") {
          return answer > condition.answer;
        }
      }
      if (qInQuestion.textQuestion) {
        if (op === "contains") {
          let lowerAnswer = answer.toLowerCase();
          let lowerCondition = condition.answer.toLowerCase();
          return lowerAnswer.includes(lowerCondition);
        } else if (op === "does not contain") {
          let lowerAnswer = answer.toLowerCase();
          let lowerCondition = condition.answer.toLowerCase();
          return !lowerAnswer.includes(lowerCondition);
        } else if (op === "is longer than") {
          return answer.length > condition.answer;
        } else if (op === "is shorter than") {
          return answer.length < condition.answer;
        }
      }
    }

    return true;
  }

  function isInvalidated(dep) {
    for (let group of dep) {
      if (checkGroup(group)) {
        return true;
      }
    }
    return false;
  }

  function revisitNumLeft() {
    let numInvalidated = 0;
    let invalidated = [];

    for (let record of dependencies) {
      if (isInvalidated(record.dependencies)) {
        numInvalidated++;
        invalidated.push(record.affectedName);
      }
    }

    let count = 0;
    for (let q of allQuestions) {
      if (q.type !== "Description") {
        count++;
      }
    }

    // console.log('invalidated', invalidated);

    setNumQs(count - numInvalidated);
  }

  function revisitPassing() {
    let state = {};
    for (let q of allQuestions) {
      state[q.id] = clearsConditions(q);
    }
    setPassing(state);
    checkPages(state, allQuestions);

    // go through answer map and delete any not passing.
    let invalidated = [];
    for (let id in state) {
      if (!state[id] && answerMap[id]) {
        invalidated.push(id);
      }
    }

    if (invalidated.length) {
      let copy = { ...answerMap };
      for (let invalidId of invalidated) {
        delete copy[invalidId];
      }

      if (retractAnswers) {
        let decrement = !Object.keys(copy).length;
        retractAnswers(invalidated, decrement);
      }
      setNumAnswered((num) => num - invalidated.length);
      setAnswerMap(copy);
    }
  }

  const ref = useRef(null);

  function checkPages(state, allQs) {
    // Check if you need to decrement the page countso you display proper 'back' 'next' 'finish' buttons
    let pages = {};
    for (let q of allQs) {
      if (!pages[q.pageNumber]) {
        pages[q.pageNumber] = [];
      }
      if (state[q.id]) {
        pages[q.pageNumber].push(q);
      }
    }

    let numPages = 0;
    for (let page in pages) {
      if (pages[page].length) {
        numPages++;
      }
    }

    setNumPages(numPages);
  }

  useEffect(() => {
    if (allQuestions && passing) {
      revisitPassing();
    }
    if (dependencies) {
      revisitNumLeft();
    }
  }, [answerMap]);

  function getNumber(q) {
    let num = 1;
    for (let question of allQuestions) {
      if ("questionText" in question) {
        if (question?.id === q?.id) {
          return num;
        }
        num += 1;
      }
    }
  }

  return (
    <>
      {fetchProject.isLoading && <Loading></Loading>}
      {fetchProject.isError && (
        <ErrorPage
          error={fetchProject.error}
          backup={"Survey - fetchProject"}
          autoSend
        ></ErrorPage>
      )}
      {settings && (
        <div
          className={styles.page}
          style={{
            backgroundColor: settings.backgroundColor
              ? settings.backgroundColor
              : "#ffffff",
            // height: preview ? "100%" : "",
          }}
          ref={ref}
        >
          {!surveyOpen && (
            <div className={styles.alreadyTookIt}>
              This survey is closed, thank you
            </div>
          )}

          {noLongerActive && surveyOpen && (
            <div className={styles.alreadyTookIt}>Link is no longer active</div>
          )}

          {alreadyTaken &&
            !settings.returnable &&
            surveyOpen &&
            !noLongerActive && (
              <div className={styles.alreadyTookIt}>
                Looks like you already took this survey, thank you for your
                participation.
              </div>
            )}

          {!noLongerActive &&
            surveyOpen &&
            (!alreadyTaken || (alreadyTaken && settings.returnable)) && (
              <>
                {(!page || !allQuestions) && !fetchQsAndSections.isError && (
                  <Loading></Loading>
                )}

                {fetchQsAndSections.isError && (
                  <ErrorPage
                    error={fetchQsAndSections.error}
                    backup={"Survey - fetchQsAndSections"}
                    autoSend
                  ></ErrorPage>
                )}

                {page && allQuestions && passing && !completed && (
                  <div className={styles.constructionZone}>
                    {!stats &&
                      settings.showLogo &&
                      (settings.logoOnEveryPage || currPageNum == 1) && (
                        <div style={{ width: "100%", padding: "1em" }}>
                          <SurveyLogo settings={settings} source={imageUrl} />
                        </div>
                      )}
                    {settings.showProgBar && (
                      <div
                        // className={styles.stick}
                        className={`${styles.progBar} ${
                          settings.progBarSticky ? styles.stick : ""
                        } `}
                        // style={{
                        //   backgroundColor: settings.backgroundColor,
                        // }}
                        // ref={progressbar}
                      >
                        <div
                          className={`${
                            isVisible && settings.progBarSticky
                              ? styles.shadow
                              : ""
                          }`}
                        >
                          <ProgressBar
                            settings={settings}
                            numAnswered={numAnswered}
                            numQuestions={numQs}
                          ></ProgressBar>
                        </div>
                      </div>
                    )}

                    <div
                      className={styles.questionsContainer}
                      style={{
                        gap: settings.questionGap
                          ? `${settings.questionGap * 2}px`
                          : "",
                      }}
                    >
                      {stats && numQs && allQuestions && numPages && (
                        <div style={{ display: "flex", gap: "1em" }}>
                          <Stat
                            small
                            value={justQuestions?.length}
                            title={
                              justQuestions <= 1 ? "Question" : "Questions"
                            }
                          ></Stat>
                          <Stat
                            small
                            value={numPages}
                            title={numPages <= 1 ? "Page" : "Pages"}
                          ></Stat>
                          <Stat
                            small
                            value={
                              dependencies ? (
                                <i className="bi-check"></i>
                              ) : (
                                <i className="bi-x"></i>
                              )
                            }
                            valueStyle={{
                              color: dependencies ? "#C1E08D" : "#FF8878",
                            }}
                            title={"Logic"}
                          ></Stat>
                        </div>
                      )}

                      {page.map((question, i) => {
                        if (passing[question.id]) {
                          return (
                            <React.Fragment key={i}>
                              {(question?.questionText ||
                                question?.questionText === "") && (
                                <Question
                                  key={question.id}
                                  question={question}
                                  onAnswered={onAnswered}
                                  onRetract={onRetract}
                                  color={settings.baseColor}
                                  answerMap={answerMap}
                                  multipleChoiceBars={
                                    settings.multipleChoiceBars
                                  }
                                  settings={settings}
                                  otherMap={otherMap}
                                  questionNumber={
                                    question?.index
                                      ? question?.index
                                      : getNumber(question)
                                  }
                                />
                              )}
                              {!question.questionText &&
                                question?.questionText !== "" && (
                                  <Section
                                    key={question.id}
                                    section={question}
                                    settings={settings}
                                  ></Section>
                                )}
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>
                    <div className={styles.buttonsContainer}>
                      {currPageNum < numPages && (
                        <>
                          {currPageNum != 1 && (
                            <SurveyButton
                              onClick={previousPage}
                              styleType={settings.buttonStyle}
                              color={
                                settings?.buttonColor
                                  ? settings.buttonColor
                                  : settings.baseColor
                              }
                              shadow={settings?.buttonShadow}
                            >
                              Back
                            </SurveyButton>
                          )}
                          <div style={{ width: "12px" }}></div>
                          <SurveyButton
                            onClick={nextPage}
                            styleType={settings.buttonStyle}
                            color={
                              settings?.buttonColor
                                ? settings.buttonColor
                                : settings.baseColor
                            }
                            shadow={settings?.buttonShadow}
                          >
                            Next
                          </SurveyButton>
                        </>
                      )}
                      {currPageNum >= numPages && numPages > 1 && (
                        <>
                          <SurveyButton
                            onClick={previousPage}
                            styleType={settings.buttonStyle}
                            color={
                              settings?.buttonColor
                                ? settings.buttonColor
                                : settings.baseColor
                            }
                            shadow={settings?.buttonShadow}
                          >
                            Back
                          </SurveyButton>
                          <div style={{ width: "12px" }}></div>
                          <SurveyButton
                            onClick={finishSurvey}
                            styleType={settings.buttonStyle}
                            color={
                              settings?.buttonColor
                                ? settings.buttonColor
                                : settings.baseColor
                            }
                            shadow={settings?.buttonShadow}
                            disabled={
                              settings.finishWithoutFinishing
                                ? false
                                : numQs !== numAnswered
                            }
                            finish
                          >
                            Finish
                          </SurveyButton>
                        </>
                      )}
                      {currPageNum === numPages && numPages == 1 && (
                        <SurveyButton
                          onClick={finishSurvey}
                          styleType={settings.buttonStyle}
                          color={
                            settings?.buttonColor
                              ? settings.buttonColor
                              : settings.baseColor
                          }
                          shadow={settings?.buttonShadow}
                          disabled={
                            settings.finishWithoutFinishing
                              ? false
                              : numQs !== numAnswered
                          }
                          finish
                        >
                          {projectId === "bd9425a5-2299-4695-a045-6a02abe5ead3"
                            ? "Avsluta"
                            : "Finish"}
                        </SurveyButton>
                      )}
                      {settings.showPageProg && (
                        <div className={styles.pageCounter}>
                          Page {currPageNum} of {numPages}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {completed && (
                  <SurveyCompleted
                    message={finishedMsg}
                    returnToSurvey={() => setCompleted(false)}
                    settings={settings}
                  />
                )}
              </>
            )}
        </div>
      )}
      <div
        className={styles.footer}
        style={{
          fontSize: window.innerWidth < 700 ? ".7em" : "",
          minHeight: "70px",
        }}
      >
        <div className={styles.poweredBy} style={{ gap: ".3em" }}>
          Powered By{" "}
          <a href={"https://reactiondata.com"} target="_blank">
            Reaction
          </a>{" "}
          <img src={require("assets/images/circlelogo.png")} height={10} />
        </div>
      </div>
    </>
  );
}

export default Survey;
